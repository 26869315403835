@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    color: #464669;
}

@layer base {
    :root {
        --primary-900: #176CFF;
        --primary-800: #157EFB;
        --primary-700: #3672be;
        --primary-600: #2373FF;
        --primary-500: #2A7CE2;
        --primary-400: #5A9BD8;
        --grey-line: #DDDDDD;
        --grey-900: #383838;
        --grey-800: #454545;
        --grey-700: #9A9A9A;
        --grey-600: #C6C6C6;
        --grey-500: #5E5E5E;
        --red-invalid: #E40000;
        --light-blue-900: #D5E5FB;
        --light-blue-800: #F1F8FF;
        --light-blue-700: #F2F8FF;
        --light-blue-600: #C0DAFF;
    }
}

@layer components {
    .input-field-group label{
        @apply block mb-2 text-sm font-bold text-grey-500;    
    }
    .input-field-group input, .input-field-group select, .input-field-group textarea{
        @apply shadow-sm border border-grey-600 text-sm rounded-lg focus:ring-primary-800 focus:outline-primary-800 block w-full p-2.5;
    }
    .input-field-group select {
        @apply py-3
    }
    .input-field-group p{
        @apply text-sm text-redInvalid;
    }
    /* .input-field-group.invalid-field label{
        @apply text-redInvalid;
    } */
    .input-field-group.invalid-field input, .input-field-group.invalid-field select{
        @apply border-redInvalid;
    }

    .app-label {
        @apply font-bold text-gray-500 text-2xl;
    }
    .app-link {
        @apply text-sm text-primary-400 font-bold
    }
}
