.app-container {
    width: 100vw;
    height: 100vh;
}
/* .app-container {
  width: 100%;
  max-width: 80rem;
  background-color: red;
  height: 100px;
} */

:disabled {
  opacity: .8;
}

.custom-spinner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}

.custom-spinner div.texttransform {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--primary-800);
  font-size: 2vw;
}
.custom-spinner div.texttransform b {
  transform: scaleY(0);
  animation: texttransform_in 1.5s infinite cubic-bezier(0.76, 0, 0.24, 1);
}
.custom-spinner div.texttransform b:nth-child(1) {
  animation-delay: 0.15s;
}
.custom-spinner div.texttransform b:nth-child(2) {
  animation-delay: 0.30s;
}
.custom-spinner div.texttransform b:nth-child(3) {
  animation-delay: 0.45s;
}
.custom-spinner div.texttransform b:nth-child(4) {
  animation-delay: 0.60s;
}
.custom-spinner div.texttransform b:nth-child(5) {
  animation-delay: .75s;
}
.custom-spinner div.texttransform b:nth-child(6) {
  animation-delay: 0.9s;
}
.custom-spinner div.texttransform b:nth-child(7) {
  animation-delay: 1.05s;
}
@keyframes texttransform_in {
  0% {
    transform-origin: 50% 100%;
    transform: scaleY(0)
  } 25% {
    transform-origin: 50% 100%;
    transform: scaleY(1)
  } 75% {
    transform-origin: 50% 0%;
    transform: scaleY(1)
  } 100% {
    transform-origin: 50% 0%;
    transform: scaleY(0)
  }
}

.cp_tree-table {
  font-size: 13px;
  /* min-width: 1575px; */
}

/* Header */

.cp_tree-table .cp_tree-table_header {
  font-weight: 700;
  border-bottom-color: var(--light-blue-600);
  border-bottom-width: 2px;
  height: 80px !important;
  flex-grow: 0 !important;
}

.cp_tree-table .cp_tree-table_viewport > div {
  overflow: auto !important;
}

/* Rows */
.cp_tree-table .cp_tree-table_row {
  padding: 4px 0;
  height: 80px !important;
  font-weight: 700;
}

/* Cells */

.cp_tree-table .cp_tree-table_cell,
.cp_tree-table .cp_tree-table_header-cell {
  display: flex;
  align-items: center;
  padding: 0 10px;
  
  /* border-top: 1px solid rgba(55, 53, 47, 0.2);
  border-bottom: 1px solid rgba(55, 53, 47, 0.2); */
}

.cp_tree-table .cp_tree-table_row  .cp_tree-table_cell {
  background: white;
  height: 80px !important;
}


/* .cp_tree-table .cp_tree-table_viewport {
  height: 1000px !important;
  overflow: auto !important;
}

.cp_tree-table .cp_tree-table_viewport div:first-child .cp_tree-table_mover {
  overflow: auto !important;
} */

